/* Sidebar.css */
.sidebar-container {
    width: 250px;
    height: 100vh;
    padding: 20px;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;
  }
  
  .sidebar-item {
    padding: 10px 0;
    cursor: pointer;
    color: #495057;
  }
  
  .sidebar-item:hover {
    color: #000;
  }
  
  .coming-soon {
    color: #ff9800; /* Cor laranja */
    border: 1px solid #ff9800;
    border-radius: 4px;
    padding: 2px 6px;
    display: inline-block;
    margin-left: 8px;
  }
  