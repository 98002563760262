/* Estilo para a modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1300;
  /* Z-index alto para garantir que a modal fique acima de outros elementos */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  /* Fundo com opacidade */
}

.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  width: 90%;
  /* Ajusta a largura para 90% */
  max-width: 600px;
  /* Aumenta o tamanho máximo para 600px */
  border-radius: 10px;
  /* Bordas arredondadas mais suaves */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Adiciona sombra para dar profundidade */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Estilo para os contêineres de entrada */
.input-container {
  margin-bottom: 15px;
}

/* Estilo para o contêiner de upload */
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #007bff;
  border-radius: 8px;
  cursor: pointer;
  color: #007bff;
  margin-top: 20px;
  text-align: center;
  background-color: #f9f9f9;
  /* Fundo leve para destacar a área de upload */
  transition: background-color 0.3s ease, border-color 0.3s ease;
  /* Transição suave */
}

/* Estilo quando arrastando o arquivo para a área de upload */
.upload-container.drag-active {
  border-color: #00aaff;
  background-color: #e0f7ff;
  /* Fundo mais claro quando ativo */
}

.upload-container+.upload-container {
  margin-top: 20px;
}

.upload-container button {
  width: 100%;
  margin: 10px 0;
}

/* Estilo para o contêiner de botões */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container button {
  margin: 0 10px;
  width: 150px;
}

.success-message {
  display: flex;
  align-items: center;
  color: green;
  font-weight: bold;
  margin-top: 20px;
}

.success-message .MuiSvgIcon-root {
  margin-right: 8px;
}

/* Estilo para o botão de remoção */
.remove-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f44336;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.remove-button:hover {
  background-color: #d32f2f;
}

/* Estilo para o link do arquivo */
.file-link {
  color: #1976d2;
  text-decoration: none;
  font-weight: 500;
}

.file-link:hover {
  text-decoration: underline;
}

/* Estilo para a visualização do arquivo */
.file-preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 6px;
  width: fit-content;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Adiciona sombra leve */
}

.file-preview a {
  margin-right: 10px;
}

.file-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Estilo para o ícone de sucesso */
.check-icon {
  color: #4caf50;
  margin-left: 5px;
}

/* Estilo adicional para a mensagem de sucesso */
.success-message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.success-text {
  color: green;
}

/* Adiciona responsividade */
@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    max-width: none;
    padding: 15px;
  }

  .button-container button {
    width: auto;
    margin: 5px;
  }
}