/* Container principal */
.DisparadorContainer {
  flex-grow: 1;
  padding: 20px;
}

/* Estilos para a pesquisa */
.SearchContainer {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  margin-bottom: 20px;
  width: 300px;
}


/* Estilos para a coluna de ícones */
.IconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilos para o ícone */
.BookIcon {
  color: #3f51b5;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.BookIcon:hover {
  transform: scale(1.2);
  color: #303f9f;
}

/* Tooltip personalizado */
.CustomTooltip {
  background-color: #3f51b5;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 4px;
}

/* Estilo do modal customizado */
.CustomModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.CustomModal .MuiTypography-h6 {
  font-weight: bold;
}

.CustomModal .MuiTypography-body1 {
  margin-bottom: 8px;
}
